import md5 from 'js-md5';

/**
 * 说明：自定义函数或常量；
 * 范围：全局引用的函数及变量；
 * 适用：需大部分功能均有适用，或需大量适用，否则不再该文档内定义；
 * */
// 对象深拷贝
function deepClone(obj) {
    const objectMap = new Map();
    const _deepClone = (value) => {
        const type = typeof value;
        if (type !== 'object' || type === null) {
            return value;
        }
        if (objectMap.has(value)) {
            return objectMap.get(value);
        }
        const result = Array.isArray(value) ? [] : {};
        objectMap.set(value, result);
        for (const key in value) {
            result[key] = _deepClone(value[key]);
        }
        return result;
    }
    return _deepClone(obj);
}

function generateSignature(apiParams, token) {
    // 将参数按字典序升序排序
    const sortedParams = Object.keys(apiParams).sort();
    // 拼接参数键值对
    const paramsString = sortedParams.map(key => key + apiParams[key]).join('');
    // 获取签名前缀
    const signPrefix = 'songshanliu';
    // 拼接签名字符串
    const signString = paramsString + signPrefix + token + signPrefix;
    // 对签名字符串进行 URL 编码
    const encodedSignString = encodeURIComponent(signString);
    // 计算 MD5 哈希值
    return md5(encodedSignString);
}

export {
    deepClone, generateSignature
}
