<!-- 首页 -->
<template>
    <div class="container">

        <!-- PC端 -->
        <div class="pc">
            <!-- banner -->
            <div class="banner">
                <el-carousel indicator-position="none" height="480px">
                    <el-carousel-item v-for="item in banner" :key="item">
                        <img class="banner_img" :src="item">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <!-- 内容1 -->
            <div class="news_box">
                <div class="news">
                    <!-- 机构新闻 -->
                    <div class="news_left">
                        <div class="left_title">
                            <p>— 机构新闻</p>
                            <div>MORE</div>
                        </div>
                        <!-- 视频 列表 -->
                        <div class="video_box">
                            <video controls width="100%" height="100%">
                                <source src="@/assets/mp4/video1.mp4" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div>

                        <!-- 新闻列表 -->
                        <!-- <div class="news_item" @click="$router.push('/NewsPage')">
                            <img src="@/assets/home/xw1.png" alt="" class="news_img">
                            <div class="em"></div>
                            <div class="news_text">
                                <p>扶贫:帮助扶贫对象开发经济、发展生产<br>
                                    宗旨:扶助扶贫对象摆脱贫困 <br>
                                    扶贫对象:贫困地区和贫困户
                                </p>
                                <p>发布时间： 2024-02-08</p>
                            </div>
                        </div> -->

                        <!-- <div class="news_item" @click="$router.push('/NewsPage')">
                            <img src="@/assets/home/xw2.png" alt="">
                            <div class="em"></div>
                            <div class="news_text">
                                <p>铁路助力乡村振兴微电影《回家的礼物》 亮相龙年“村晚”</p>
                                <p>发布时间： 2024-02-08</p>
                            </div>
                        </div>
                        <div class="news_item" @click="$router.push('/NewsPage')">
                            <img src="@/assets/home/xw3.jpg" alt="">
                            <div class="em"></div>
                            <div class="news_text">
                                <p>“加多宝·学子情”乡村振兴人才奖学金 颁奖仪式顺利举办</p>
                                <p>发布时间： 2023-12-29</p>
                            </div>
                        </div>
                        <div class="news_item" @click="$router.push('/NewsPage')">
                            <img src="@/assets/home/xw4.png" alt="">
                            <div class="em"></div>
                            <div class="news_text">
                                <p>推动乡村儿童服务高质量发展，共话童伴妈妈项目成果——童伴妈妈项目2023年度工作交流会在京召开</p>
                                <p>发布时间： 2023-12-05</p>
                            </div>
                        </div> -->

                    </div>
                    <div class="news_right">
                        <!-- 爱心捐赠 -->
                        <div class="donation">
                            <p>— 爱心捐赠</p>
                            <p>本年度</p>
                            <div class="donation_money">
                                <p>捐款总额为：</p><span>653,463,703.80</span>
                                <p>元</p>
                            </div>
                            <div class="donation_btn">
                                <button>项目信息公开</button>
                                <button>年度审计报告</button>
                            </div>
                        </div>
                        <!-- 捐赠查询 -->
                        <div class="query_box">
                            <p>— 捐赠查询</p>
                            <div class="query">
                                <input type="text" name="" id="" placeholder="输入姓名或单位名称查询信息">｜
                                <img src="@/assets/home/query_icon.jpg" alt="">
                            </div>
                            <p>— 关注我们</p>
                            <div class="focus_us">
                                <div>
                                    <img src="@/assets/home/wechat.png" alt="">
                                    <p>基金会微信公众号</p>
                                </div>
                                <div>
                                    <img src="@/assets/home/weibo.png" alt="">
                                    <p>基金会微博</p>
                                    <button class="us_btn">+ 关注</button>
                                </div>
                            </div>
                            <p>— 自媒体平台</p>
                            <div class="media_box">
                                <div>
                                    <img src="@/assets/home/zmt1.jpg" alt="">
                                    <p>善行者微博</p>
                                </div>
                                <div>
                                    <img src="@/assets/home/zmt2.jpg" alt="">
                                    <p>善行100微博</p>
                                </div>
                                <div>
                                    <img src="@/assets/home/zmt4.jpg" alt="">
                                    <p>公益未来微博</p>
                                </div>

                            </div>
                            <div class="media_box">
                                <div>
                                    <img src="@/assets/home/zmt1.jpg" alt="">
                                    <p>善行者微信号</p>
                                </div>
                                <div>
                                    <img src="@/assets/home/zmt4.jpg" alt="">
                                    <p>公益未来微信号</p>
                                </div>
                                <div>
                                    <img src="@/assets/home/zmt3.jpg" alt="">
                                    <p>捐一元</p>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <!-- 内容2 -->
            <!-- <div class="Tabs">
                <div class="tabs_box">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="国内公益项目" name="first">
                            <div class="tabs_list">
                                <div class="tabs_item">
                                    <img src="@/assets/home/tabs1.jpg" alt="">
                                    <p>行善者</p>
                                </div>
                                <div class="tabs_item">
                                    <img src="@/assets/home/tabs2.jpg" alt="">
                                    <p>行善100</p>
                                </div>
                                <div class="tabs_item">
                                    <img src="@/assets/home/tabs3.jpg" alt="">
                                    <p>童伴妈妈项目</p>
                                </div>
                                <div class="tabs_item">
                                    <img src="@/assets/home/tabs4.jpg" alt="">
                                    <p>爱加餐项目</p>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="国际公益项目" name="second">
                            <div class="tabs_list">
                                <div class="tabs_item">
                                    <img src="@/assets/home/2tabs1.jpg" alt="">
                                    <p>国际综合项目</p>
                                </div>
                                <div class="tabs_item">
                                    <img src="@/assets/home/2tabs2.jpg" alt="">
                                    <p>国际爱心包裹项目</p>
                                </div>
                                <div class="tabs_item">
                                    <img src="@/assets/home/2tabs3.jpg" alt="">
                                    <p>国际微笑儿童项目</p>
                                </div>
                                <div class="tabs_item">
                                    <img src="@/assets/home/2tabs3.jpg" alt="">
                                    <p>童伴妈妈项目</p>
                                </div>

                            </div>
                        </el-tab-pane>

                    </el-tabs>

                </div>
            </div> -->
            <div class="Tabs">
                <div class="tabs_box">
                    <el-tabs v-model="activeName2" @tab-click="handleClick">
                        <el-tab-pane label="公益基金" name="first">
                            <div class="tabs_list">
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/20161025093728_4862.png" alt=""> -->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/bottom2.jpg" alt=""> -->

                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/bottom3.jpg" alt=""> -->

                                </div>
                                <div class="tabs_item2">


                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="捐赠机构" name="second">
                            <div class="tabs_list">
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs1.jpg" alt="">-->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs2.jpg" alt="">-->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs3.jpg" alt="">  -->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs3.jpg" alt=""> -->
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="合作伙伴" name="partnership">
                            <div class="tabs_list">
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs1.jpg" alt="">-->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs2.jpg" alt="">-->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs3.jpg" alt="">  -->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs3.jpg" alt=""> -->
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="合作媒体" name="media">
                            <div class="tabs_list">
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs1.jpg" alt="">-->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs2.jpg" alt="">-->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs3.jpg" alt="">  -->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs3.jpg" alt=""> -->
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="爱心大使" name="plenipotentiary">
                            <div class="tabs_list">
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs1.jpg" alt="">-->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs2.jpg" alt="">-->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs3.jpg" alt="">  -->
                                </div>
                                <div class="tabs_item2">
                                    <!-- <img src="@/assets/home/2tabs3.jpg" alt=""> -->
                                </div>
                            </div>
                        </el-tab-pane>

                    </el-tabs>

                </div>
            </div>


        </div>

        <!-- 移动端 -->
        <!-- <div class="h5">
            <div class="news_left">
                <div class="left_title">
                    <p>— 机构新闻</p>
                    <div>MORE</div>
                </div>
                <div class="news_item">
                    <div class="news_img">
                        <img src="@/assets/home/xw1.png" alt="">
                    </div>
                    <div class="em"></div>
                    <div class="news_text">
                        <p>此上春山 与书相阅：南网知行儿童读书节4·23活动在黔成功举办</p>
                        <p>发布时间： 2024-02-08</p>
                    </div>
                </div>
                <div class="news_item">
                    <img src="@/assets/home/xw2.png" alt="" class="news_img">
                    <div class="em"></div>
                    <div class="news_text">
                        <p>铁路助力乡村振兴微电影《回家的礼物》 亮相龙年“村晚”</p>
                        <p>发布时间： 2024-02-08</p>
                    </div>
                </div>
                <div class="news_item">
                    <img src="@/assets/home/xw3.jpg" alt="" class="news_img">
                    <div class="em"></div>
                    <div class="news_text">
                        <p>“加多宝·学子情”乡村振兴人才奖学金 颁奖仪式顺利举办</p>
                        <p>发布时间： 2023-12-29</p>
                    </div>
                </div>
                <div class="news_item">
                    <img src="@/assets/home/xw4.png" alt="" class="news_img">
                    <div class="em"></div>
                    <div class="news_text">
                        <p>推动乡村儿童服务高质量发展，共话童伴妈妈项目成果——童伴妈妈项目2023年度工作交流会在京召开</p>
                        <p>发布时间： 2023-12-05</p>
                    </div>
                </div>
                <div class="news_item">
                    <img src="@/assets/home/xw5.jpg" alt="" class="news_img">
                    <div class="em"></div>
                    <div class="news_text">
                        <p>南网知行书屋项目阶段性成果及提升行动发布会举行---360间南网知行书屋全面建成</p>
                        <p>发布时间：2023-12-01</p>
                    </div>
                </div>

                <div class="news_item">
                    <img src="@/assets/home/xw6.jpg" alt="" class="news_img">
                    <div class="em"></div>
                    <div class="news_text">
                        <p>乡村儿童发展论坛发布2023年乡村儿童发展报告</p>
                        <p>发布时间： 2023-11-30</p>
                    </div>
                </div>
                <div class="news_item">
                    <img src="@/assets/home/xw7.jpg" alt="" class="news_img">
                    <div class="em"></div>
                    <div class="news_text">
                        <p>吉木乃县中国石油乡村环境综合整治提升项目推进会顺利举行</p>
                        <p>发布时间： 2023-11-27</p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            banner: [
                require('@/assets/home/1.jpg'),
                require('@/assets/home/2.jpg'),
                require('@/assets/home/3.jpg'),
                require('@/assets/home/4.png'),
                require('@/assets/home/5.jpg'),

            ],
            activeName: 'second',
            activeName2: 'first',
        };
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }
};
</script>

<style scoped>
.container {
    background-color: #f2f2f2;
    height: 100%;
    padding-bottom: 200px;
}

/* 在宽度大于等于 768px 的屏幕上应用下面的样式 */
.h5 {
    display: none;
}

.pc {
    margin: 0;
    padding: 0;
    width: 100%;
}

/* 轮播图 */
.banner {
    overflow: hidden;
    min-width: 1200px;

}

.banner_img {
    width: 100%;
    height: 100%;
}

/* 机构新闻 */
.news_box {
    width: 100%;
    display: flex;
    margin: 0 auto;
    margin-top: 30px;
    justify-content: center;
}

.news {
    display: flex;
    gap: 20px;
}

.news_left {
    background-color: #fff;
    width: 740px;
    padding: 25px;
}

.left_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #e11b1c;
    font-size: 18px;
}

.news_item {
    display: flex;
    padding: 26px 0;
    border-bottom: 1px solid #d6d6d6;
    /* width: 100% !important; */
}

.news_img {
    width: 100%;
    height: 100%;

}

.news_item:last-child {
    border: 0;
}

.news_text p {
    margin-top: 5px;
    font-size: 13px !important;
}

.news_text p:nth-child(2) {
    margin-top: 50px;
    font-size: 14px;
    color: #b3a6c4;
}

.em {
    background-color: #d6d6d6;
    width: 8px;
    height: 15px;
    margin: 9px 10px 0 15px;
}

/* 爱心捐赠 */
.donation {
    background-color: #fff;
    padding: 25px 30px;
    margin-bottom: 20px;

    p {
        color: #666;
        font-size: 14px;
    }

    .donation_money {
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        p {
            color: #666;
            font-size: 14px;
            margin-bottom: 0;

        }

        span {
            color: #e11b1c;
            font-size: 24px;
        }
    }
}

.donation p:first-child {
    color: #e11b1c;
    font-size: 18px;
}

.donation_btn {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    button {
        background-color: #e11b1c;
        color: #fff;
        border-radius: 4px;
        padding: 10px 20px;
        border: 0;
        margin-bottom: 23px;
    }
}

.query_box {
    background-color: #fff;
    padding: 25px 30px;

    p {
        color: #e11b1c;
        font-size: 18px;
    }


    .query {
        border: 1px solid #e8e8e8;
        padding: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        input {
            flex: 1;
            border: none;

        }


        img {
            width: 34px;
            height: 24px;
        }
    }



}

.focus_us {
    display: flex;
    text-align: center;
    gap: 20px;
    padding-bottom: 50px;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 50px;

    img {
        width: 115px;
    }

    p {
        color: #666;
        font-size: 14px;
    }

    .us_btn {
        padding: 5px 20px;
        background-color: #f76a20;
        color: #fff;
        border: 0;
        border-radius: 4px;
        cursor: pointer;
    }




}

.media_box {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    text-align: center;
    gap: 20px;

    img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    p {
        color: #666;
        font-size: 14px;
    }
}

.Tabs {
    width: 100%;
    display: flex;
    margin: 0 auto;
    margin-top: 30px;
    justify-content: center;
}

.tabs_box {
    background-color: #fff;
    padding: 25px 30px;

    .tabs_list {
        display: flex;
        gap: 20px;

        .tabs_item {
            width: 263px;
            height: 250px;
        }

        .tabs_item:hover {
            color: #e11b1c;
        }
    }
}

::v-deep(.el-tabs__item.is-active) {
    color: #e11b1c;
}

::v-deep(.el-tabs__active-bar) {
    background-color: #e11b1c;
}

::v-deep(.el-tabs__item):hover {
    color: #e11b1c;
}

.tabs_item2 {
    width: 263px;
    height: 100px;
}

.tabs_item2 img {
    width: 116px;
    height: 86px;
}

/* PC端 */
@media only screen and (min-width: 768px) {
    .news_item {
        width: 100% !important;

        img {
            width: 300px !important;
            height: 200px !important;
        }
    }

    .news_text {
        p {
            font-size: 20px !important;
        }
    }
}




/* 在此处编写针对放大超过 150% 时的样式 */
@media screen and (min-resolution: 150dpi) {
    .banner {
        min-width: 1000px;
        height: 300px;
    }

    .banner_img {
        height: 75%;
    }

    .news {
        width: 85%;
    }

    .left_title {
        font-size: 14px;
    }

    .news_text p {
        font-size: 14px !important;
    }

    .news_text {
        font-size: 10px !important;
    }

    .donation p {
        font-size: 14px !important;
    }

    .donation_money p {
        font-size: 12px !important;
    }

    .tabs_box {
        width: 80%;
    }

    /* .news_item {

        img {
            width: 250px !important;
            height: 150px !important;
        }
    } */


}


/* H5页面（移动端） */
@media only screen and (max-width: 767px) {
    .banner {
        display: none;
    }

    .news_right {
        display: none;

    }

    .news_box {
        margin-top: 0;
        width: 100% !important;
    }

    .news {
        width: 100% !important;
    }

    .news_text p {
        font-size: 14px !important;
    }

    .news_text p:nth-child(2) {
        font-size: 12px !important;

    }

    .tabs_list {
        display: flex;
        flex-direction: column;
    }

    /* .tabs_item:last-child {
        display: none;
    } */


    .news_item {
        display: flex;
        justify-content: space-between;

    }

    .news_item img {
        width: 150px !important;
        height: 100px !important;
    }

    .video_box {
        width: 100%;
        height: 200px;

    }

}
</style>