import {axios} from "@/http";

// 登录
function login(data) {
    return axios({
        url: "api/login",
        method: "post",
        data
    });
}

// 注册
function register(data) {
    return axios({
        url: "api/register",
        method: "post",
        data
    });
}

export {
    login,register
};
