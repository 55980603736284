import router from "@/router";
import axios from "axios";
import {
    Message
} from "element-ui";
import {
    generateSignature,
    deepClone
} from "../utils/md5";

// axios.defaults.baseURL = "http://192.168.1.32/"; // 设置所有请求的前半部分
// axios.defaults.baseURL = "http://8.130.23.230:8808/"; // 设置所有请求的前半部分
axios.defaults.baseURL = "http://45.207.49.184:8808/"; // 设置所有请求的前半部分
// axios.defaults.headers.post['Content-Type'] = 'application/json' // 设置post统一请求头
// axios.defaults.timeout = 20000; // 20秒请求时间
let urlConfig = axios.defaults.baseURL

// 添加请求拦截器
axios.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么
        let new_data = deepClone(config.data);
        const adminToken = localStorage.getItem('token') ? localStorage.getItem('token') : '62f55b4575a19c8c702f07af76aedc01';
        if (!new_data) new_data = {}
        new_data['sign'] = generateSignature(config.data || {}, adminToken);
        config.headers.token = adminToken;
        config.data = new_data
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 添加响应拦截器
axios.interceptors.response.use(
    (res) => {
        if (res.data.code === 164) {
            setTimeout(() => {
                localStorage.removeItem('adminToken')
                localStorage.removeItem('declareBackstage')
                window.location.href = "/";
            }, 500);
        }

        if (res.data.code === 401) {
            Message.error('请先登录');
            localStorage.clear();
            // router.push('/');
            setTimeout(() => {
                window.location.href = "/";
            }, 1000)
            res.data.code = 200;
        }
        if (res.data.code === 402) {
            // Message.error(res.data.msg);
            localStorage.clear();
            // localStorage.removeItem('adminToken');
            setTimeout(() => {
                window.location.href = "/";
            }, 1000)
        }

        return res;
    },
    (error) => {
        // 响应发生错误时的处理
        Message.error('网络错误');
        router.push('/networkError')
        return Promise.reject(error);
    }
);

export {
    axios,
    urlConfig
};