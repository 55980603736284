<template>
    <!-- 顶部tab栏 -->
    <div>
        <div class="pc">
            <header>
                <div class="top">
                    <div class="logo">
                        <img src="@/assets/tab/logo.png" alt="">
                        <!-- <p>惠民农业基金会</p> -->
                    </div>
                    <div class="center">
                        <img src="@/assets/tab/index_top1.jpg" alt="">
                    </div>
                    <div class="right_box">
                        <div class="right">
                            <!-- <div class="contact_way">
                                <img src="@/assets/tab/site_icon.png" alt="">
                                <p>北京市海淀区花园路<br>甲2号4号楼一层101</p>
                            </div>
                            <div class="contact_way">
                                <img src="@/assets/tab/phone_icon.png" alt="">
                                <p>010-82872688</p>
                            </div>
                            <div class="contact_way">
                                <img src="@/assets/tab/mail_icon.png" alt="">
                                <p>cfrd@cfrd.org.cn</p>
                            </div> -->
                            <div v-if="!isLoggedIn" class="contact_way" style="cursor: pointer;"
                                @click="dialogVisible = true">
                                <img src="@/assets/tab/login.png" alt="">
                                <p>登录 / 注册</p>
                            </div>
                            <div v-else style="cursor: pointer;" class="contact_way2">
                                <div @click="$router.push('/MemberInfo')" style="display: flex; align-items: center;">
                                    <img src="@/assets/tab/login.png" alt="" class="login_img2">
                                    <p class="login_text">会员中心</p>
                                </div>
                                <div style="margin-left: 10px;">
                                    <p @click="layout" class="out">/ 退出</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="nav">
                    <ul class="tab">
                        <li class="tab_item" @click="$router.push('/')">首页</li>
                        <!-- <li class="tab_item" @click="$router.push('/OrgData')">信息公开
                            <img src="@/assets/tab/down.png" alt=""  >
                            <div class="dropdown_menu">
                                <div class="dropdown_item" @click="$router.push('/OrgData')">机构信息公开</div>
                                <div class="dropdown_item" @click="$router.push('/ProjectData')">项目信息公开</div>
                                <div class="dropdown_item" @click="$router.push('/DonationQuery')">捐赠查询</div>
                                <div class="dropdown_item" @click="$router.push('/DonationWay')">捐赠方式</div>
                                <div class="dropdown_item" @click="$router.push('/MIS')">管理制度</div>
                                <div class="dropdown_item" @click="$router.push('/CH')">投诉电话</div>
                            </div>
                        </li> -->
                        <li class="tab_item" @click="$router.push('/PressCenter')">机构新闻</li>

                        <li class="tab_item" @click="$router.push('/DPW')">国内公益项目</li>
                        <li class="tab_item" @click="$router.push('/YNMT')">国际公益项目
                            <!-- <img src="@/assets/tab/down.png" alt=""  > -->
                            <div class="dropdown_menu">
                                <div class="dropdown_item" @click="$router.push('/YNMT')">国际综合项目</div>
                                <div class="dropdown_item" @click="$router.push('/YNMT')">国际爱心包裹</div>
                                <div class="dropdown_item" @click="$router.push('/YNMT')">国际微笑儿童项目</div>


                            </div>
                        </li>
                        <li class="tab_item" @click="$router.push('/SynopsisInfo')">关于我们
                            <!-- <img src="@/assets/tab/down.png" alt="" > -->
                            <div class="dropdown_menu">
                                <div class="dropdown_item" @click="$router.push('/SynopsisInfo')">基本信息</div>
                                <div class="dropdown_item">机构章程</div>
                                <div class="dropdown_item">理事会</div>
                            </div>
                        </li>
                        <!-- <li class="tab_item" @click="$router.push('/MyDonate')">我要捐赠</li> -->
                        <li v-if="isNiusb" class="tab_item" @click="$router.push('/WithdrawalRequest')">提现申请</li>
                        <li v-if="isNiusb" class="tab_item" @click="$router.push('/PayCode')">充值</li>
                    </ul>
                    <div class="tab_right">
                        <div class="search">
                            <input type="text" placeholder="请输入搜索关键字">
                            <img src="@/assets/tab/search.png" alt="">
                        </div>

                        <p>English</p>
                    </div>
                </div>

                <el-dialog title="登录" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
                    <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
                        <el-form-item label="用户名">
                            <el-input v-model="formLabelAlign.name"></el-input>
                        </el-form-item>
                        <el-form-item label="密码">
                            <el-input v-model="formLabelAlign.region" type="password"></el-input>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <span class="tips" @click="register"> 没有账户？ 前往注册</span>

                        <button class="btn1" @click="dialogVisible = false">取 消</button>
                        <button class="btn2" @click="login2">确 定</button>
                    </span>
                </el-dialog>
                <el-dialog :visible.sync="showRegisterDialog" title="注册" width="30%">

                    <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
                        <el-form-item label="用户名">
                            <el-input v-model="formLabelAlign.name"></el-input>
                        </el-form-item>
                        <el-form-item label="密码">
                            <el-input v-model="formLabelAlign.region" type="password"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码">
                            <el-input v-model="formLabelAlign.region2" type="password"></el-input>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <span class="tips" @click="login"> 已有账户？ 前往登录</span>

                        <button class="btn1" @click="dialogVisible = false">取 消</button>
                        <button class="btn2" @click="register2">确 定</button>
                    </span>
                </el-dialog>
            </header>

        </div>
        <div class="h5">
            <div class="content_h5">
                <div class="h5_top">
                    <!-- <img src="@/assets/tab/index_logo.png" alt=""> -->
                    <div class="logo_box">
                        <img src="@/assets/tab/logo.png" alt="" class="h5_logo">
                    </div>
                    <div class="h5_right_box">
                        <div v-if="!isLoggedIn" class="h5_login" style="cursor: pointer;"
                            @click="$router.push('/login')">
                            <img class="login_img" src="../assets/tab/login2.png" alt="">
                            <p class="login_text">登录 / 注册</p>
                        </div>
                        <div v-else class="h5_login" style="cursor: pointer;">
                            <div @click="$router.push('/MemberInfo')" class="h5_login">
                                <img class="login_img" src="../assets/tab/login2.png" alt="">
                                <p class="login_text">会员中心</p>
                            </div>
                            <div style="margin-left: 10px;">
                                <p @click="layout" class="out">/ 退出</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="h5_right">
                    <div class="h5_contact_way">
                        <img src="@/assets/tab/site_icon.png" alt="">
                        <p>北京市海淀区花园路甲2号4号楼一层101</p>
                    </div>
                    <div class="h5_contact_way">
                        <img src="@/assets/tab/phone_icon.png" alt="">
                        <p>010-82872688</p>
                    </div>
                    <div class="h5_contact_way">
                        <img src="@/assets/tab/mail_icon.png" alt="">
                        <p>cfrd@cfrd.org.cn</p>
                    </div>
                </div> -->

                <div class="h5_search">
                    <ul class="h5_tab">
                        <li class="tab_item" @click="$router.push('/')">首 页</li>
                        <li class="tab_item" @click="$router.push('/SynopsisInfo')">基本信息</li>
                        <li v-if="isNiusb" class="tab_item" @click="$router.push('/WithdrawalRequest')">提现申请</li>
                        <li v-if="isNiusb" class="tab_item" @click="$router.push('/PayCode')">充 值</li>
                        <!-- <li class="tab_item" @click="$router.push('/MyDonate')">我要捐赠</li> -->
                        <!-- <li class="tab_item" @click="$router.push('/OrgData')">信息公开
                            <img src="@/assets/tab/down.png" alt="">
                            <div class="dropdown_menu">
                                <div class="dropdown_item" @click="$router.push('/OrgData')">机构信息公开</div>
                                <div class="dropdown_item" @click="$router.push('/ProjectData')">项目信息公开</div>
                                <div class="dropdown_item" @click="$router.push('/DonationQuery')">捐赠查询</div>
                                <div class="dropdown_item" @click="$router.push('/DonationWay')">捐赠方式</div>
                                <div class="dropdown_item" @click="$router.push('/MIS')">管理制度</div>
                                <div class="dropdown_item" @click="$router.push('/CH')">投诉电话</div>
                            </div>
                        </li> -->


                        <li class="tab_item" @click="$router.push('/PressCenter')">机构新闻</li>
                        <!-- <li class="tab_item" @click="$router.push('/DPW')">国内公益项目</li>
                        <li class="tab_item" @click="$router.push('/YNMT')">国际公益项目
                            <img src="@/assets/tab/down.png" alt="">
                            <div class="dropdown_menu">
                                <div class="dropdown_item" @click="$router.push('/YNMT')">国际综合项目</div>
                                <div class="dropdown_item" @click="$router.push('/YNMT')">国际爱心包裹</div>
                                <div class="dropdown_item" @click="$router.push('/YNMT')">国际微笑儿童项目</div>


                            </div>
                        </li> -->

                        <!-- <li class="tab_item" >关于我们
                            <img src="@/assets/tab/down.png" alt="">
                            <div class="dropdown_menu">
                                <div class="dropdown_item" @click="$router.push('/SynopsisInfo')">基本信息</div>
                                <div class="dropdown_item">机构章程</div>
                            <div class="dropdown_item">理事会</div>
                            </div>
                        </li> -->

                    </ul>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import { login, register } from '@/api/login';
import md5 from 'js-md5';
export default {
    data() {
        return {
            dialogVisible: false,
            showRegisterDialog: false,
            labelPosition: 'right',
            formLabelAlign: {
                name: '',
                region: '',
                region2: '',
                type: ''
            },
            active: 2,
            isLoggedIn: true,//是否登录
            isNiusb: true,
        };
    },
    created() {
        this.isLoggedIn = localStorage.getItem('token') ? true : false;
        this.isNiusb = localStorage.getItem('token') ? true : false;
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(() => {
                    done();
                })
                .catch(() => { });
        },
        register() {
            this.dialogVisible = false;
            this.showRegisterDialog = true;
            this.formLabelAlign.name = '';
            this.formLabelAlign.region = '';
            this.formLabelAlign.region2 = '';
        },
        login() {
            this.showRegisterDialog = false;
            this.dialogVisible = true
            this.formLabelAlign.name = '';
            this.formLabelAlign.region = '';
            this.formLabelAlign.region2 = '';
        },
        login2() {
            let requestData = {
                phone_number: this.formLabelAlign.name,
                password: md5(this.formLabelAlign.region),
            }
            login(requestData).then(res => {
                if (res.data.code === 200) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    localStorage.setItem("token", res.data.result.token);
                    localStorage.setItem("mobile", this.formLabelAlign.name);
                    this.dialogVisible = false;
                    this.isLoggedIn = true;
                    this.isNiusb = true;
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        register2() {
            if (this.password !== this.password2) {
                this.$message({
                    message: "两次密码不一致",
                    type: 'success'
                });
                return;
            }
            let requestData = {
                phone_number: this.formLabelAlign.name,
                password: md5(this.formLabelAlign.region),
                confirm_password: md5(this.formLabelAlign.region2),
            }
            register(requestData).then(res => {
                if (res.data.code === 200) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.dialogVisible = true;
                    this.showRegisterDialog = false;
                    this.formLabelAlign.name = '';
                    this.formLabelAlign.region = '';
                    this.formLabelAlign.region2 = '';
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        /**
     * 退出登录
     */
        layout() {
            // localStorage.clear();
            // this.$message({
            //     message: '退出成功',
            //     type: 'success'
            // });
            // this.$router.push('/');
            localStorage.removeItem('token');
            this.$message({
                message: '退出成功',
                type: 'success'
            });
            this.isLoggedIn = false,
                this.isNiusb = false,
                setTimeout(() => {
                    window.location.href = "/";
                }, 1000)

        }

    }
};
</script>

<style scoped>
* {
    font-family: 微软雅黑;
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    white-space: normal;

}

/* pc 端 */
@media only screen and (min-width: 768px) {
    .h5 {
        display: none;
    }

    .out {
        font-size: 20px;
    }

    .logo {
        width: 96% !important;
    }

    .logo p {
        font-size: 24px;
        margin-left: 20px;
        color: #e11b1c;
    }

    .logo img {
        width: 30%;
        height: 100%;
    }

    .right_box {
        text-align: right;
    }

    .right_box {
        display: flex !important;
        align-items: center !important;
        justify-content: space-evenly !important;
        background-color: #e11b1c !important;
        width: 100% !important;
        height: 100% !important;
    }
}




/* 在此处编写针对放大超过 125% 时的样式 */
@media screen and (min-resolution: 125dpi) {


    * {
        font-size: 10px;
    }

    .logo p {
        font-size: 24px;
        margin-left: 20px;
        color: #e11b1c;
    }

    .out {
        font-size: 24px;
    }

    .right_box {
        width: 150% !important;
    }

    .tab {
        margin-left: 0 !important;
    }

    .tab_item {
        font-size: 12px !important;
    }

    .tips {
        margin-right: 18px !important;
        font-size: 12px !important;
    }

    .btn1 {
        font-size: 12px !important;
        padding: 8px !important;
    }

    .btn2 {
        font-size: 12px !important;
        padding: 8px !important;
    }
}


@media only screen and (min-resolution: 175dpi) {


    * {
        font-size: 8px !important;
    }

    .top {
        height: 70px !important;
    }

    .center img {
        height: 70px !important;
    }

    .logo img {
        width: 45%;
    }

    .logo p {
        font-size: 24px;
        margin-left: 20px;
        color: #e11b1c;
    }


    .out {
        font-size: 24px;
    }

    .contact_way {
        display: flex;
        align-items: center;
    }

    .contact_way img {
        width: 20px !important;
        height: 20px !important;
    }

    .contact_way p {
        margin: 0 15px;
        color: #fff;
        font-size: 8px;
    }

    .contact_way2 {
        display: flex;
        align-items: center;


    }

    .contact_way2 .login_img2 {
        width: 20px !important;
        height: 20px !important;
    }

    .tab_item {
        font-size: 8px !important;
    }

    .search input {
        padding: 5px !important;
    }

    .search img {
        width: 24px !important;
        height: 24px !important;
    }

    .tab_right p {
        font-size: 8px !important;
    }

    .dropdown_menu {
        width: 120px !important;

    }

    .dropdown_item {
        padding: 10px !important;
    }

    .tips {
        margin-right: 18px !important;
        font-size: 10px !important;
    }

    .btn1 {
        font-size: 12px !important;
        padding: 6px !important;
    }

    .btn2 {
        font-size: 12px !important;
        padding: 6px !important;
    }
}

/* 移动端 */
@media only screen and (max-width: 768px) {
    * {
        margin: 0;
        padding: 0;
    }

    .pc {
        display: none;
    }

    .h5_top {
        width: 95%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;


        .h5_top_logo {
            font-size: 18px !important;
            margin-left: 20px !important;
            color: #e11b1c !important;
        }

    }


    .logo_box {
        width: 130px !important;
        margin-left: 10px !important;
    }

    .h5_logo {
        width: 100%;
        height: 100%;
    }


    .out {
        font-size: 12px !important;
    }

    .login_text {
        font-size: 12px !important;
    }

    .login_img {
        width: 20px !important;
        height: 20px !important;
        margin-right: 4px !important;
    }

    .h5_right {
        background-color: #e11b1c !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        color: #fff !important;
        height: 50px !important;
        padding: 0 10% !important;

    }

    .h5_contact_way {
        display: flex !important;
        align-items: center !important;
        margin-right: 15px !important;
    }

    .h5_contact_way p {
        font-size: 14vw !important;
    }

    .h5_contact_way img {
        width: 20px !important;
        height: 20px !important;
        margin-right: 5px !important;
    }

    .h5_search {
        width: 100% !important;
        background-color: #e11b1c !important;
        color: #fff !important;

    }

    .h5_tab {
        /* display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        justify-items: center;
        justify-items: center;
        gap: 10px; */
        display: flex !important;
        white-space: nowrap !important;
        overflow-x: auto !important;
        overflow-y: hidden !important;
        width: 100% !important;
        color: #fff !important;

        /* 隐藏垂直滚动条 */

    }

    .tab_item {
        flex: 0 0 auto !important;
        /* width: 100px; */
        margin: 20px 20px !important;
        font-size: 13px !important;
        color: #fff !important;
    }



}

header {
    display: block;
    unicode-bidi: isolate;
    width: 100%;
    margin: 0 auto;
}

.top {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 89px;
    border-bottom: 1px solid #e9e9e9;
}

.h5_right_box {
    display: flex !important;
    align-items: center !important;
    justify-content: space-evenly !important;
}

.h5_login {
    display: flex !important;
    align-items: center !important;
}

.right {
    display: flex !important;
    align-items: center !important;

}

.contact_way {
    display: flex !important;
    align-items: center !important;
}

.contact_way p {
    margin: 0 15px !important;
    color: #fff !important;
}

.contact_way img {
    width: 40px !important;
    height: 40px !important;
}

.contact_way2 {
    display: flex;
    align-items: center;
}

.contact_way2 .login_img2 {
    width: 40px;
    height: 40px;
}

.contact_way2 p {
    margin: 0 15px !important;
    color: #fff !important;
}

.login_text {
    font-size: 20px;
}

.out {
    font-size: 20px;
}

.center {
    width: 75px;
    height: 100%;

}

.nav {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 4%;
    height: 63px;

    /* width: 97%; */

}

.tab {
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 4%;
}

.tab_item {
    margin: 0 20px;
    color: #5e5e5e;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    img {
        width: 7px;
        height: 5px;
        margin-left: 5px;
    }
}



.tab_right {
    display: flex;
    align-items: center;


    .search {
        display: flex;
        align-items: center;
        margin-right: 34px;

        input {
            margin-right: 10px;
            border: 1px solid rgb(221, 221, 221);
            padding: 10px 15px;
        }

        img {
            width: 38px;
            height: 38px;
            cursor: pointer;
        }

    }

    p:hover {
        color: #e11b1c;
        cursor: pointer;
    }


}


.btn1 {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
    font-size: 15px;
    margin-right: 20px;
}

.btn2 {
    padding: 10px;
    border: 1px solid #409eff;
    background-color: #409eff;
    color: #fff;
    border-radius: 4px;
    font-size: 15px !important;
    margin-right: 20px !important;
}

.tips {
    margin-right: 60px !important;
    cursor: pointer !important;
    font-size: 16px !important;
}

::v-deep(.el-input__inner) {
    margin-bottom: 30px !important;
}

.tab_item:hover {
    color: #e11b1c;
}

.dropdown_menu {
    display: none !important;
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    background-color: #fff !important;
    border-top: 3px solid #e11b1c !important;
    border-left: 1px solid #ededed !important;
    border-right: 1px solid #ededed !important;
    border-bottom: 1px solid #ededed !important;
    font-size: 16px !important;
    color: #5e5e5e !important;
    width: 220px !important;
    z-index: 100 !important;
}

.dropdown_menu div::before {
    content: '';
    display: inline-block;
    width: 3px;
    height: 3px;
    background-color: #cfcfcf;
    border-radius: 50%;
    margin-right: 20px;
}

.tab_item:hover .dropdown_menu {
    display: block;
    z-index: 100 !important;
}

.dropdown_item {
    text-align: left !important;
    padding: 15px !important;
    border-bottom: 1px solid #ededed !important;
}

.dropdown_item:hover {
    color: #e11b1c;
}
</style>