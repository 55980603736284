<template>
  <div id="app">
    <TabHead v-if="!$route.meta.hideHeader" />
    <router-view />
  </div>
</template>

<script>

export default {
  watch: {
    $route(to, from) {
      if (to.meta.hideHeader !== from.meta.hideHeader) {
        // 处理需要隐藏或显示 TabHead 组件逻辑
      }
    }
  }
}
</script>

<style lang="less"></style>
