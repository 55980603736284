import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/HomeIndex.vue';
import Login from '../views/loginIndex.vue';
import Register from '../views/registerIndex.vue';

Vue.use(VueRouter);

const routes = [{
    path: '/',
    name:'home',
    component: Home,
  },

  //登录H5
  {
    path: '/login',
    component: Login,
    meta: {
      hideHeader: true
    } // 标记登录页，不需要显示头部
  },
  {
    path: '/register',
    component: Register,
    meta: {
      hideHeader: true
    } // 标记注册页，不需要显示头部
  },
  //机构信息公开
  {
    path: '/OrgData',
    component: () => import('../views/DiscloseInformation/OrgData.vue')
  },
  // 项目信息公开
  {
    path: '/ProjectData',
    component: () => import('../views/DiscloseInformation/ProjectData.vue')
  },
  // 捐赠查询
  {
    path: '/DonationQuery',
    component: () => import('../views/DiscloseInformation/DonationQuery.vue')
  },
  // 捐赠方式
  {
    path: '/DonationWay',
    component: () => import('../views/DiscloseInformation/DonationWay.vue')
  },
  // 管理制度
  {
    path: '/MIS',
    component: () => import('../views/DiscloseInformation/MIS.vue')
  },
  // 投诉电话
  {
    path: '/CH',
    component: () => import('../views/DiscloseInformation/CH.vue')
  },
  // 机构新闻
  {
    path: '/PressCenter',
    component: () => import('../views/PressCenter.vue')
  },
  // 国内公益项目
  {
    path: '/DPW',
    component: () => import('../views/DPW.vue')
  },
  // 国际公益项目
  {
    path: '/YNMT',
    component: () => import('../views/YNMT.vue')
  },
  // 关于我们
  {
    path: '/SynopsisInfo',
    component: () => import('../views/AboutUs/SynopsisInfo.vue')
  },
  // 机构章程
  {
    path: '/OrgRule',
    component: () => import('../views/AboutUs/OrgRule.vue')
  },
  // 理事会
  {
    path: '/TrusteeCouncil',
    component: () => import('../views/AboutUs/TrusteeCouncil.vue')
  },
  // 关于我们
  {
    path: '/SynopsisInfo',
    component: () => import('../views/AboutUs/SynopsisInfo.vue')
  },
  // 我要捐赠
  {
    path: '/MyDonate',
    component: () => import('../views/MyDonate.vue')
  },
  // 提现申请
  {
    path: '/WithdrawalRequest',
    component: () => import('../views/WithdrawalRequest.vue')
  },

  // 收款码
  {
    path: '/PayCode',
    component: () => import('../views/PayCode.vue')
  },


  // 会员中心
  {
    path: '/MemberInfo',
    component: () => import('../views/MemberInfo.vue'),
    meta: {
      hideHeader: true
    }
  },
  // 新闻详情页
  {
    path: '/NewsPage',
    component: () => import('../views/NewsPage.vue'),
    meta: {
      hideHeader: true
    }
  },
  {
    path: "*",
    redirect: "/",
    hidden: true,
  },
];

const router = new VueRouter({
  mode: 'hash',
  routes
});





export default router;