<template>
    <!-- 注册 -->
    <div class="content_h5">
        <van-icon name="arrow-left" @click="$router.push('/login')" class="back" />
        <div class="login_title">
            <p>注 册</p>
        </div>
        <div class="login_content">
            <van-form @submit="onSubmit" class="login_form">
                <van-field v-model="username" name="手机号" label="手机号" placeholder="请输入您的手机号码"
                    :rules="[{ required: true }]" />
                <van-field v-model="password" type="password" name="密 码" label="密 码" placeholder="请输入您的密码"
                    :rules="[{ required: true }]" />
                <van-field v-model="password2" type="password" name="确认密码" label="确认密码" placeholder="请确认您的密码"
                    :rules="[{ required: true }]" />
                <div class="login_btn">
                    <van-button round block type="info" native-type="submit">注 册</van-button>
                </div>
            </van-form>
            <div class="login_text">
                <p>已有账号 ？去 </p>
                <p @click="$router.push('/login')">登 录</p>
            </div>
        </div>
    </div>
</template>

<script>
import { register } from '@/api/login';
import { Toast } from 'vant';
import md5 from 'js-md5';
export default {
    data() {
        return {
            username: '',
            password: '',
            password2: '',
        };
    },
    methods: {
        onSubmit() {
            if (this.password !== this.password2) {
                Toast.fail('两次密码不一致');
                return;
            }
            let requestData = {
                phone_number: this.username,
                password: md5(this.password),
                confirm_password: md5(this.password2),
            }
            register(requestData).then(res => {
                if (res.data.code === 200) {
                    Toast.success(res.data.msg);
                    this.$router.push('/login');
                } else {
                    Toast.fail(res.data.msg);
                }
            })
            // console.log('submit', values);
        },
    },
};
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.back {
    font-size: 24px;
    margin: 16px;
    color: #fff;
}

.content_h5 {
    background-color: #ff5e62;
    height: 100%;
}

.login_title {
    width: 100%;
    /* text-align: center; */
    padding-top: 10%;
}

.login_title p {
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    margin: 30px;
}


.login_content {
    width: 100%;
    height: 100%;
    background-color: #fff;
    margin: 0 auto;
    padding: 20px 0;
    border-radius: 40px 40px 0 0;
}

.login_form {
    width: 80%;
    margin: 0 auto;
}

::v-deep(.van-cell) {
    margin: 14px 0;
    font-size: 15px;
    color: #000;
}

::v-deep(.van-cell::after) {
    border: 1px solid #c4c4c4;
}

::v-deep(.van-field) {
    padding: 14px 10px;
    border-radius: 8px;
}

::v-deep(.van-button--info) {
    background-color: #e04f56;
    border: 0;
    font-size: 20px;
    margin-top: 45%;
}

.login_btn {
    margin: 20px;
}

.login_text {
    display: flex;
    justify-content: center;

    p:nth-child(2) {
        font-weight: 700;
    }

}
</style>